* {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
p,
ul,
ol,
li {
  padding: 0;
  margin: 0;
}

.component-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: #fff;
    font-size: 42px;
    padding: 0 15px;
    text-align: center;
  }
}
